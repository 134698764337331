import { FC } from 'react';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import IconProps from './IconProps';

const ExportIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      {...dataAttributeProps(props)}
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4852 11.9498L15.6567 10.1214L17.071 8.70718L20.6065 12.2427V13.6569L17.071 17.1925L15.6567 15.7782L17.4852 13.9498L9.99988 13.9498L9.99988 11.9498H17.4852Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4C4.44772 4 4 4.44772 4 5V18C4 18.5523 4.44772 19 5 19H13.5C14.0523 19 14.5 18.5523 14.5 18V14.95H9.99988C9.4476 14.95 8.99988 14.5023 8.99988 13.95L8.99988 11.95C8.99988 11.6848 9.10524 11.4304 9.29278 11.2429C9.48031 11.0553 9.73467 10.95 9.99988 10.95H14.5V7H11.5V4H5ZM14.5 11.95H9.99988V13.95L14.5 13.95V11.95Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExportIcon;
